import React, { useEffect, useRef, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/components/ui/dropdown-menu";
import { ChevronDown, X } from "lucide-react";
import { cn } from "src/lib/utils";

export type SelectOption = {
  label: string;
  value: string | number;
};

type MultipleSelectProps = {
  multiple: true;
  value: SelectOption[];
  onChange: (value: SelectOption[]) => void;
};

type SingleSelectProps = {
  multiple?: false;
  value?: SelectOption | null;
  onChange: (value: SelectOption | undefined) => void;
};

type SelectProps = {
  options: SelectOption[];
  className?: string;
} & (SingleSelectProps | MultipleSelectProps);

export function MultiSelect({ multiple, value, onChange, options, className }: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  function clearOptions() {
    if (multiple) {
      onChange([]);
    } else {
      onChange(undefined);
    }
  }
  function selectOption(option: SelectOption) {
    if (multiple) {
      if (value.includes(option)) {
        onChange(value.filter((o) => o !== option));
      } else {
        onChange([...value, option]);
      }
    } else if (option !== value) {
      onChange(option);
    }
  }
  function isOptionSelected(option: SelectOption) {
    if (multiple) {
      return value.some((v) => v.value === option.value);
    }
    return option === value;
  }

  useEffect(() => {
    if (isOpen) setHighlightedIndex(0);
  }, [isOpen]);

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.target !== containerRef.current) return;
      switch (e.code) {
        case "Enter":
        case "Space":
          setIsOpen((prev) => !prev);
          if (isOpen) selectOption(options[highlightedIndex]);
          break;
        case "ArrowUp":
        case "ArrowDown": {
          if (!isOpen) {
            setIsOpen(true);
            break;
          }

          const newValue = highlightedIndex + (e.code === "ArrowDown" ? 1 : -1);
          if (newValue >= 0 && newValue < options.length) {
            setHighlightedIndex(newValue);
          }
          break;
        }
        case "Escape":
          setIsOpen(false);
          break;
        default:
          break;
      }
    };
    containerRef.current?.addEventListener("keydown", handler);

    return () => {
      containerRef.current?.removeEventListener("keydown", handler);
    };
  }, [isOpen, highlightedIndex, options]);

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <div
          ref={containerRef}
          tabIndex={0}
          role="combobox"
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-controls="dropdown-menu"
          className={cn(
            "relative flex min-h-10 border border-solid border-dark-input bg-background px-3 py-2 text-sm font-medium ring-offset-dark-ring-input-ring-focus placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 text-dark-primary-foreground items-center rounded-md cursor-pointer focus-visible:ring-1 focus-visible:ring-dark-ring-dark focus-visible:ring-offset-1 focus-visible:outline-none",
            isOpen && "ring-dark-ring-dark ring-offset-1 outline-none ring-1",
            className,
          )}
        >
          <span className="flex flex-wrap flex-1 w-full h-full gap-2">
            {multiple
              ? value.map((v) => (
                  <div
                    key={v.value}
                    className="flex items-center gap-2 border border-solid border-dark-input bg-background px-2 py-1 rounded-md"
                    onClick={(e) => e.preventDefault()}
                  >
                    {v.label}
                    <button
                      type="button"
                      aria-label={`Remove ${v.label}`}
                      tabIndex={isOpen ? -1 : 0}
                      onPointerDown={(e) => {
                        // Stop the event here so it never triggers the DropdownMenuTrigger
                        e.stopPropagation();
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        selectOption(v); // Remove the selected option
                      }}
                      className="hover:bg-accent/10 rounded-full p-0.5"
                    >
                      <X className="hover:text-red-500 text-sm text-dark-muted-foreground h-3 w-3" />
                    </button>
                  </div>
                ))
              : value?.label || "Select..."}
          </span>

          <div className="flex items-center gap-1">
            {(multiple ? value.length > 0 : value) && (
              <button
                type="button"
                aria-label="Clear selection"
                onPointerDown={(e) => {
                  // Stop the event here so it never triggers the DropdownMenuTrigger
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  clearOptions();
                }}
                className="hover:bg-accent/10 rounded-full p-1"
              >
                <X className="text-dark-muted-foreground hover:text-red-500 h-3 w-3" />
              </button>
            )}
            <ChevronDown size={20} className={`ml-auto transition-transform ${isOpen ? "rotate-180" : ""}`} />{" "}
          </div>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        id="dropdown-menu"
        role="listbox"
        aria-labelledby={containerRef.current?.id}
        className="flex flex-col w-full min-w-[200px] z-[9999] gap-1"
      >
        {options.map((option, index) => (
          <DropdownMenuItem
            key={option.value}
            onSelect={() => {
              selectOption(option);
              setIsOpen(false);
            }}
            onMouseEnter={() => setHighlightedIndex(index)}
            className={`${isOptionSelected(option) && "bg-accent/30"} hover:bg-accent/10`}
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
