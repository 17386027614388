import { makeRequest } from "src/api/request";

export const getDashboardData = async () =>
  await makeRequest.get<AgentAPI.LocalPulse.Dashboard.Response>("/agent/local-pulse/local-monitors/dashboard/");

export const getLocalMonitorList = async (params?: AgentAPI.LocalPulse.List.Request) =>
  await makeRequest.get<AgentAPI.LocalPulse.List.Response>("/agent/local-pulse/local-monitors/", { params });

export const createLocalMonitor = async (data: AgentAPI.LocalPulse.CreateLocalMonitor.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.CreateLocalMonitor.Response>("/agent/local-pulse/local-monitors/create-monitor/", { data });

export const createNewMonitor = async (data: AgentAPI.LocalPulse.CreateNewMonitor.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.CreateNewMonitor.Response>("/agent/local-pulse/monitors/create-monitor/", data );

export const getLocalMonitorConfigBulkList = async (data?: AgentAPI.LocalPulse.monitorConfigBulkList.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.monitorConfigBulkList.Response>("/agent/local-pulse/monitors/bulk-get/", data);

export const getLocalMonitors = async (data?: AgentAPI.LocalPulse.GetLocalMonitors.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.GetLocalMonitors.Response>("/agent/local-pulse/local-monitors/get-monitors/", data);

export const getMonitorsList = async (params?: AgentAPI.LocalPulse.monitorConfigList.Request) =>
  await makeRequest.get<AgentAPI.LocalPulse.monitorConfigList.Response>("/agent/local-pulse/monitors/", { params });

export const updateMonitorConfig = async (data: AgentAPI.LocalPulse.UpdateMonitorConfig.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.UpdateMonitorConfig.Response>("/agent/local-pulse/monitors/monitor-update/", data);

export const runLocalMonitorTest = async (data: AgentAPI.LocalPulse.RunLocalMonitorTest.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.RunLocalMonitorTest.Response>("/agent/local-pulse/local-monitors/test-local-monitor/", data);

export const runMonitorTest = async (data: AgentAPI.LocalPulse.RunMonitorTest.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.RunMonitorTest.Response>("/agent/local-pulse/monitors/test-monitor/", data);

export const updateLocalMonitorStatus = async (data: AgentAPI.LocalPulse.UpdateLocalMonitor.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.UpdateLocalMonitor.Response>("/agent/local-pulse/local-monitors/update-local-monitor/", data);

export const runPreloadMonitors = async (data: AgentAPI.LocalPulse.RunPreloadMonitors.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.RunPreloadMonitors.Response>("/agent/local-pulse/local-monitors/preload-monitor/", data);

export const runDailyMonitors = async (data: AgentAPI.LocalPulse.RunDailyMonitors.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.RunDailyMonitors.Response>("/agent/local-pulse/local-monitors/run-daily-monitor/", data);

export const deleteMonitorConfig = async (data: AgentAPI.LocalPulse.DeleteMonitorConfig.Request) =>
  await makeRequest.post<AgentAPI.LocalPulse.DeleteMonitorConfig.Response>("/agent/local-pulse/monitors/delete-monitor/", data);

