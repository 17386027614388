/* eslint-disable max-len */
import React from "react";

interface GoogleIconProps {
  style?: React.CSSProperties;
  size?: number | string;
}

const GoogleIcon: React.FC<GoogleIconProps> = (
  {
    style,
    size = "20px",
  },
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_986_18658)">
      <path
        d="M28.0427 14.6817C28.0427 13.73 27.9656 12.7732 27.8009 11.837H14.5957V17.2281H22.1577C21.8439 18.9669 20.8357 20.505 19.3593 21.4824V24.9805H23.8708C26.52 22.5421 28.0427 18.9411 28.0427 14.6817Z"
        fill="#4285F4"
      />
      <path
        d="M14.5957 28.3602C18.3716 28.3602 21.5558 27.1204 23.8759 24.9804L19.3644 21.4824C18.1092 22.3363 16.4888 22.8199 14.6009 22.8199C10.9485 22.8199 7.85163 20.3558 6.74048 17.0429H2.08496V20.649C4.46159 25.3765 9.30231 28.3602 14.5957 28.3602Z"
        fill="#34A853"
      />
      <path
        d="M6.73533 17.0429C6.14889 15.3042 6.14889 13.4214 6.73533 11.6826V8.07654H2.08496C0.0992857 12.0324 0.0992857 16.6931 2.08496 20.649L6.73533 17.0429Z"
        fill="#FBBC04"
      />
      <path
        d="M14.5957 5.90052C16.5917 5.86966 18.5207 6.62071 19.9663 7.99937L23.9633 4.00231C21.4324 1.62568 18.0732 0.319043 14.5957 0.360196C9.3023 0.360196 4.46159 3.34384 2.08496 8.07653L6.73534 11.6826C7.84134 8.3646 10.9433 5.90052 14.5957 5.90052Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_986_18658">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(0.31543 0.359253)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleIcon;
