import { useMutation, useQuery } from "@tanstack/react-query";
import {
  login,
  loginWithSocial,
  refreshToken,
  verifyToken,
} from "src/api/authentication";
import type { AxiosError, AxiosResponse } from "axios";
import { useEffect } from "react";
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query/src/types";
import { useAuthStore } from "src/store/account";

type IUseLoginRequest = () => UseMutationResult<
  AxiosResponse<API.Authentication.ObtainToken.Response>,
  AxiosError<API.Authentication.ObtainToken.ErrorResponse>,
  API.Authentication.ObtainToken.Request
>;

export const useLoginRequest: IUseLoginRequest = () => (
  useMutation({
    mutationFn: async (data) => await login(data),
  })
);

type IUseTokenRefreshQueryData = UseQueryResult<AxiosResponse<API.Authentication.RefreshToken.Response>,
  AxiosError<API.Authentication.RefreshToken.ErrorResponse>>;

type IUseTokenRefreshRequest = (token?: string) => IUseTokenRefreshQueryData;

export const useTokenRefreshRequest: IUseTokenRefreshRequest = (token) => {
  const {
    refreshToken: savedRefreshToken,
    setAccessToken,
    setRefreshToken,
    setIsAuthenticated,
  } = useAuthStore();
  const refresh = token || savedRefreshToken;

  const queryData: IUseTokenRefreshQueryData = useQuery(
    ["useTokenRefreshRequest", refresh],
    async () => await refreshToken({ refresh }),
    {
      enabled: false,
      retry: false,
      staleTime: 0,
    },
  );

  useEffect(() => {
    if (queryData.status === "error") {
      setIsAuthenticated(false);
      setAccessToken("");
      setRefreshToken("");
    }

    if (queryData.status === "success") {
      setIsAuthenticated(true);
      setRefreshToken(queryData.data.data.access);
      if (token) {
        setRefreshToken(token);
      }
    }
  }, [queryData.status]);

  return queryData;
};

type IUseTokenVerifyQueryData = UseQueryResult<AxiosResponse<API.Authentication.VerifyToken.Response>,
  AxiosError<API.Authentication.VerifyToken.ErrorResponse>>;

type IUseTokenVerifyRequest = () => IUseTokenVerifyQueryData;

export const useTokenVerifyRequest: IUseTokenVerifyRequest = () => {
  const {
    accessToken: token,
    setIsAuthenticated,
  } = useAuthStore();

  const queryData: IUseTokenVerifyQueryData = useQuery(
    ["useTokenVerifyRequest", token],
    async () => await verifyToken({ token }),
    {
      enabled: false,
      retry: false,
    },
  );

  const {
    refetch: requestRefreshToken,
  } = useTokenRefreshRequest();

  useEffect(() => {
    if (queryData.status === "error") {
      requestRefreshToken();
    }

    if (queryData.status === "success") {
      setIsAuthenticated(true);
    }
  }, [queryData.status]);

  return queryData;
};

type IUseLoginFromSocialRequest = (data: API.Authentication.ObtainTokenSocial.Request)
  => UseQueryResult<AxiosResponse<API.Authentication.ObtainTokenSocial.Response>,
  AxiosError<API.Authentication.ObtainTokenSocial.ErrorResponse>>;

export const useLoginFromSocialRequest: IUseLoginFromSocialRequest = (data) => useQuery(
  ["", data],
  async () => await loginWithSocial(data),
  {
    enabled: false,
    staleTime: 0,
    retry: false,
  },
);
