import React from "react";
import CenterPreloader from "src/components/loaders/CenterPreloader";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useStateListRequest } from "src/hooks/api/area";
import { getLocalMonitorList } from "src/api/agent/local-pulse";
import EditableTable from "./EditableTable";

const LocalMonitorPage: React.FC = () => {
  const [filter, setFilter] = React.useState<AgentAPI.LocalPulse.List.Filter>({});
  const [search, setSearch] = React.useState("");
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ["monitors", search, filter],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getLocalMonitorList({
        page: pageParam,
        search: search || undefined,
        ...filter,
      });
      return response;
    },
    getNextPageParam: (lastPage, pages) => (lastPage.data.next ? pages.length + 1 : undefined),
    staleTime: 1000 * 60, // Data becomes stale after 1 minute
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
    refetchOnMount: true,
    // Only refetch if the data is older than 5 minutes
    refetchInterval: 1000 * 60 * 5,
  });

  const { data: stateData } = useStateListRequest();

  const handleFilterChange = (name: string, value: string) => {
    if (value === "") {
      // Create a new object without the specified property
      const { [name as keyof AgentAPI.LocalPulse.List.Filter]: removed, ...rest } = filter;
      setFilter(rest);
    } else {
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };

  return (
    <div className="relative">
      <EditableTable
        data={data}
        stateData={stateData}
        filter={filter}
        handleFilterChange={handleFilterChange}
        search={search}
        setSearch={setSearch}
        isLoading={isLoading}
        hasNextPage={hasNextPage || false}
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        refetch={refetch}
      />
    </div>
  );
};

export default LocalMonitorPage;
