import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import config from "src/config";
import type { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import { useProfileStore } from "src/store/profile";

const defaultLibraries: Libraries = ["places", "geometry", "visualization", "drawing", "marker"];

export const useGoogleMapLoader = () => {
  return useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.GOOGLE_API_KEY,
    libraries: defaultLibraries,
  });
};
