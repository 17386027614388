import { useMutation, useQuery } from "@tanstack/react-query";
import {
  confirmEmail,
  confirmEmailRequest, confirmPhoneRequest,
  createAccount,
  requestPasswordReset,
  resetPassword,
  subscribeToNewsletter, verifyPhoneNumber,
} from "src/api/registration";
import { AxiosError, AxiosResponse } from "axios";
import { UseQueryResult, UseMutationResult } from "@tanstack/react-query/src/types";

type IUseCreateAccountRequest = () => UseMutationResult<
  AxiosResponse<API.Registration.CreateAccount.Response>,
  AxiosError<API.Registration.CreateAccount.ErrorResponse>,
  API.Registration.CreateAccount.Request
>;

export const useRegistrationRequest: IUseCreateAccountRequest = () => (
  useMutation({
    mutationFn: async (data) => await createAccount(data),
  })
);

type IUseRequestPasswordResetRequest = (data: API.Registration.RequestPasswordReset.Request) => UseQueryResult<
  AxiosResponse<API.Registration.RequestPasswordReset.Response>,
  AxiosError<API.Registration.RequestPasswordReset.ErrorResponse>
>;

export const useRequestPasswordResetRequest: IUseRequestPasswordResetRequest = (data) => (
  useQuery(
    ["useRequestPasswordResetRequest", data],
    async () => await requestPasswordReset(data),
    {
      enabled: false,
      staleTime: 0,
      retry: false,
    },
  )
);

type IUseResetPasswordRequest = (data: API.Registration.ResetPassword.Request) => UseQueryResult<
  AxiosResponse<API.Registration.ResetPassword.Response>,
  AxiosError<API.Registration.ResetPassword.ErrorResponse>
>;

export const useResetPasswordRequest: IUseResetPasswordRequest = (data) => (
  useQuery(
    ["useResetPasswordRequest", data],
    async () => await resetPassword(data),
    {
      enabled: false,
      staleTime: 0,
      retry: false,
    },
  )
);

type IUseConfirmEmailRequest = (data: API.Registration.ConfirmEmail.Request) => UseQueryResult<
  AxiosResponse<API.Registration.ConfirmEmail.Response>,
  AxiosError<API.Registration.ConfirmEmail.ErrorResponse>
>;

export const useConfirmEmailRequest: IUseConfirmEmailRequest = (data) => (
  useQuery(
    ["useResetPasswordRequest", data],
    async () => await confirmEmail(data),
    {
      enabled: false,
      staleTime: 0,
      retry: false,
    },
  )
);

type IUseConfirmEmailRequestRequest = () => UseMutationResult<
  AxiosResponse<API.Registration.ConfirmEmailRequest.Response>,
  AxiosError<API.Registration.ConfirmEmailRequest.ErrorResponse>,
  API.Registration.ConfirmEmailRequest.Request
>;

export const useConfirmEmailRequestRequest: IUseConfirmEmailRequestRequest = () => (
  useMutation({
    mutationFn: async (data) => await confirmEmailRequest(data),
  })
);

type IUseSubscribeToNewsletterRequest = () => UseMutationResult<
  AxiosResponse<API.Registration.SubscribeToNewsletter.Response>,
  AxiosError<API.Registration.SubscribeToNewsletter.ErrorResponse>,
  API.Registration.SubscribeToNewsletter.Request
>;

export const useSubscribeToNewsletterRequest: IUseSubscribeToNewsletterRequest = () => (
  useMutation({
    mutationFn: async (data) => await subscribeToNewsletter(data),
  })
);

type IUseVerifyPhoneNumberRequest = () => UseMutationResult<
  AxiosResponse<API.Registration.VerifyPhoneNumber.Response>,
  AxiosError<API.Registration.VerifyPhoneNumber.ErrorResponse>,
  API.Registration.VerifyPhoneNumber.Request
>;

export const useVerifyPhoneNumberRequest: IUseVerifyPhoneNumberRequest = () => (
  useMutation({
    mutationFn: async (data) => await verifyPhoneNumber(data),
  })
);

type IUseConfirmPhoneCodeRequest = () => UseMutationResult<
  AxiosResponse<API.Registration.ConfirmPhoneRequest.Response>,
  AxiosError<API.Registration.ConfirmPhoneRequest.ErrorResponse>,
  API.Registration.ConfirmPhoneRequest.Request
>;

export const useConfirmPhoneCodeRequest: IUseConfirmPhoneCodeRequest = () => (
  useMutation({
    mutationFn: async (data) => await confirmPhoneRequest(data),
  })
);
