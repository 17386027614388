import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLocalMonitorConfigBulkList, getLocalMonitorList, getLocalMonitors, getMonitorsList } from "src/api/agent/local-pulse";

type IUseAgentLocalMonitorListRequest = (
  params?: AgentAPI.LocalPulse.List.Request,
) => UseQueryResult<AxiosResponse<AgentAPI.LocalPulse.List.Response>, AxiosError<AgentAPI.LocalPulse.List.ErrorResponse>>;

export const useAgentLocalMonitorListRequest: IUseAgentLocalMonitorListRequest = (params) =>
  useQuery(["useLocalMonitorListRequest", "useAgentLocalMonitorListRequest", params], async () => await getLocalMonitorList(params));

type IUseAgentLocalMonitorConfigBulkListRequest = (
  params?: AgentAPI.LocalPulse.monitorConfigBulkList.Request,
) => UseQueryResult<
  AxiosResponse<AgentAPI.LocalPulse.monitorConfigBulkList.Response>,
  AxiosError<AgentAPI.LocalPulse.monitorConfigBulkList.ErrorResponse>
>;

export const useAgentLocalMonitorConfigBulkListRequest: IUseAgentLocalMonitorConfigBulkListRequest = (params) =>
  useQuery(
    ["useLocalMonitorConfigBulkListRequest", "useAgentLocalMonitorConfigBulkListRequest", params],
    async () => await getLocalMonitorConfigBulkList(params),
  );

type IUseAgentLocalMonitorConfigListRequest = (
  params?: AgentAPI.LocalPulse.monitorConfigList.Request,
) => UseQueryResult<
  AxiosResponse<AgentAPI.LocalPulse.monitorConfigList.Response>,
  AxiosError<AgentAPI.LocalPulse.monitorConfigList.ErrorResponse>
>;

export const useAgentLocalMonitorConfigListRequest: IUseAgentLocalMonitorConfigListRequest = (params) =>
  useQuery(
    ["useLocalMonitorConfigListRequest", "useAgentLocalMonitorConfigListRequest", params],
    async () => await getMonitorsList(params),
  );

type IUseAgentGetLocalMonitorsRequest = (
  params?: AgentAPI.LocalPulse.GetLocalMonitors.Request,
) => UseQueryResult<
  AxiosResponse<AgentAPI.LocalPulse.GetLocalMonitors.Response>,
  AxiosError<AgentAPI.LocalPulse.GetLocalMonitors.ErrorResponse>
>;

//get all the monitors for a given area id
export const useAgentGetLocalMonitorsRequest: IUseAgentGetLocalMonitorsRequest = (params) =>
  useQuery(["useGetLocalMonitorsRequest", "useAgentGetLocalMonitorsRequest", params], async () => await getLocalMonitors(params), {
    enabled: !!params?.area_ids && params.area_ids.length > 0,
  });
