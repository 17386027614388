import React from "react";
import { ChipMenu } from "src/components/ui/menu";
import { Outlet } from "react-router-dom";
import { useProfileHasPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";

const LocalPulsePage = () => {
  const hasVerificationPerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION);
  const hasDatabasePerm = useProfileHasPermission(MembershipPermission.ACCESS_AGENT_STR_DATABASE_MODIFICATION);
  const items = React.useMemo(() => {
    const allowedItems = [];

    if (hasVerificationPerm) {
      allowedItems.push({ to: "/agent/local-pulse/", title: "Dashboard" });
      allowedItems.push({ to: "/agent/local-pulse/local-monitor", title: "Monitor Connections" });
      allowedItems.push({ to: "/agent/local-pulse/monitor-config", title: "Monitors" });
    }

    return allowedItems;
  }, [hasVerificationPerm, hasDatabasePerm]);

  return (
    <section>
      <ChipMenu level={3} style={{ marginBottom: 24 }} items={items} />
      <Outlet />
    </section>
  );
};

export default LocalPulsePage;
