"use client";

import React, { useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "src/components/ui/table";
import { Button } from "src/components/ui/button";
import { Checkbox } from "src/components/ui/checkbox";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "src/components/ui/dialog";
import { useMutation, useQueryClient, InfiniteData } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CirclePlay, PencilIcon, Trash } from "lucide-react";
import { Input } from "src/components/ui/input";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { HoverTooltip } from "src/components/ui/hoverTooltip";
import { createNewMonitor, deleteMonitorConfig, updateMonitorConfig } from "src/api/agent/local-pulse";
import { CenterPreloader } from "src/components/loaders";
import { MultiSelect, SelectOption } from "src/components/ui/multiSelect";
import { useGetMonitorsCategoriesRequest } from "src/hooks/api/local-pulse";
import DateRestrictForm from "../component/date-restrict-form";
import { UrlInputForm } from "../component/url-input-form";
import TestMonitor from "../local-monitor/TestMonitor";

interface EditableTableProps {
  search: string;
  handleSearch: (search: string) => void;
  isLoading: boolean;
  data: InfiniteData<AxiosResponse<AgentAPI.LocalPulse.monitorConfigList.Response, any>> | undefined;
}

export default function EditableTable({ search, handleSearch, isLoading, data }: EditableTableProps) {
  const [selectedRowId, setSelectedRowId] = useState<number>(0);
  const [editData, setEditData] = useState<Partial<AgentAPI.LocalPulse.MonitorConfig>>({});
  const [isEditing, setIsEditing] = useState(false);
  const [isTesting, setIsTesting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [testMonitor, setTestMonitor] = useState<AgentAPI.LocalPulse.MonitorConfig | null>(null);
  const [queryError, setQueryError] = useState(false);
  const [flattenedData, setFlattenedData] = useState<AgentAPI.LocalPulse.MonitorConfig[]>([]);
  const [selectedData, setSelectedData] = useState<AgentAPI.LocalPulse.MonitorConfig | null>(null);
  const queryClient = useQueryClient();

  const dialogDataDisplay = (selectedID: number) => {
    setSelectedRowId(selectedID);
    if (selectedID === 0) {
      setSelectedData(null);
    } else if (selectedID === -1) {
      setSelectedData({
        id: Math.floor(Math.random() * 1000000),
        name: "New Monitor",
        query: "",
        hq: "",
        exclude_terms: "",
        exact_terms: "",
        duplicate_filter: true,
        num_of_results: 10,
        urls: [],
        date_restrict: "y1",
        categories: [],
        categories_name: [],
      });
    } else {
      const selectedRow = flattenedData.find((row) => row.id === selectedID);
      setSelectedData(selectedRow || null);
    }
  };
  
  const updateMutation = useMutation({
    mutationFn: (updateData: AgentAPI.LocalPulse.UpdateMonitorConfig.Request) => updateMonitorConfig(updateData),
    onSuccess: () => {
      // Invalidate and refetch the monitors list
      queryClient.invalidateQueries({ queryKey: ["monitors"] });

      // Reset the form state
      setIsEditing(false);
      setEditData({});
      dialogDataDisplay(0);
    },
    onError: (error) => {
      // Handle error - you might want to show a toast notification
      console.error("Failed to update monitor:", error);
    },
  });

  const { data: categoriesData, isLoading: isCategoriesLoading } = useGetMonitorsCategoriesRequest();

  const categories = useMemo(() => {
    if (!categoriesData || !categoriesData.data) return [];
    return categoriesData.data.map((category: { id: number; name: string }) => ({
      value: category.id,
      label: category.name,
    }));
  }, [categoriesData]);

  const handleEditDialog = (open: boolean) => {
    setIsEditing(open);
    if (!open) {
      setEditData({});
      setSelectedRowId(0);
    }
  };

  const handleDeleteDialog = (open: boolean) => {
    setIsDeleting(open);
    if (!open) {
      setSelectedRowId(0);
    }
  };

  const handleSave = async () => {
    // Here you would typically update your data source
    const { categories_name: _, ...dataToUpdate } = editData;
    if (!dataToUpdate.query?.trim()) {
      // Handle empty query case
      setQueryError(true);
      return;
    }

    handleEditDialog(false);

    if (selectedRowId === -1) {
      //create a new monitor
      const results = await createNewMonitor({
        name: dataToUpdate.name || "New Monitor",
        query: dataToUpdate.query || "Short-term rental",
        date_restrict: dataToUpdate.date_restrict || "y1",
        urls: dataToUpdate.urls || [],
        duplicate_filter: dataToUpdate.duplicate_filter || true,
        num_of_results: dataToUpdate.num_of_results || 10,
        categories: dataToUpdate.categories || [],
        exclude_terms: dataToUpdate.exclude_terms || "",
        exact_terms: dataToUpdate.exact_terms || "",
        hq: dataToUpdate.hq || "",
      });
      setFlattenedData([
        {
          id: results.data.id,
          name: results.data.name,
          query: results.data.query,
          date_restrict: results.data.date_restrict,
          urls: results.data.urls,
          duplicate_filter: results.data.duplicate_filter,
          num_of_results: results.data.num_of_results,
          categories: results.data.categories,
          exclude_terms: results.data.exclude_terms,
          exact_terms: results.data.exact_terms,
          hq: results.data.hq,
          categories_name: results.data.categories_name,
        },
        ...flattenedData,
      ]);
      return;
    }

    updateMutation.mutate({
      ...dataToUpdate,
      id: selectedRowId,
    });
    setFlattenedData(flattenedData.map((row) => (row.id === selectedRowId ? { ...row, ...dataToUpdate } : row)));
  };

  const deleteMonitor = async (id: number) => {
    try {
      const results = await deleteMonitorConfig({ ids: [id] });
      setFlattenedData(flattenedData.filter((row) => row.id !== id));
      handleDeleteDialog(false);
    } catch (error) {
      console.error("Failed to delete monitor:", error);
    }
  };

  React.useEffect(() => {
    if (!isTesting) {
      setTestMonitor(null);
    }
  }, [isTesting]);

  React.useEffect(() => {
    if (data && data.pages.length > 0) {
      setFlattenedData(data.pages.flatMap((page) => page.data.results));
    }
  }, [data]);

  return (
    <div className="sticky top-0 z-10 flex flex-col h-[calc(100vh-90px)]">
      <div className="bg-background flex justify-between items-center py-3 flex-wrap gap-3">
        <div className="flex items-center gap-2">
          <h2 className="text-sm font-bold">Monitors</h2>
          <div className="relative w-[280px]">
            <Input
              type="text"
              placeholder="Search monitors..."
              className="w-full"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
            />
          </div>
        </div>
        <Button
          variant="outline"
          onClick={() => {
            setIsEditing(true);
            dialogDataDisplay(-1);
          }}
        >
          Add Monitor
        </Button>
      </div>
      {isLoading ? (
        <CenterPreloader />
      ) : (
        <Table className="rounded-md border-border w-full h-10 overflow-clip relative" divClassname="flex-1 overflow-auto customScrollbar2">
          <TableHeader className="sticky w-full top-0 h-10 border-b-2 border-border rounded-t-md bg-background">
            <TableRow>
              <TableHead>Action</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Query</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Urls</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="max-h-[600px] overflow-auto">
            {flattenedData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <div className="flex gap-1">
                    <HoverTooltip content="Edit monitors for this area">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          dialogDataDisplay(row.id);
                          setIsEditing(true);
                        }}
                      >
                        <PencilIcon className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                    <HoverTooltip content="Run a test with this monitor">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit"
                        onClick={() => {
                          setTestMonitor(row);
                          setIsTesting(true);
                        }}
                      >
                        <CirclePlay className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                    <HoverTooltip content="Delete monitor">
                      <Button
                        variant="outline"
                        size="icon"
                        className="p-1 h-fit w-fit text-destructive"
                        onClick={() => {
                          setSelectedRowId(row.id);
                          handleDeleteDialog(true);
                        }}
                      >
                        <Trash className="w-3 h-3" />
                      </Button>
                    </HoverTooltip>
                  </div>
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.query}</TableCell>
                <TableCell>{row.categories_name?.join(", ") || "-"}</TableCell>
                <TableCell>{row.urls.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog open={isEditing} onOpenChange={handleEditDialog}>
        <DialogContent className="max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
          <DialogHeader>
            <DialogTitle>Edit Selected Row</DialogTitle>
            <DialogDescription />
          </DialogHeader>
          {selectedData ? (
            <div className="flex-1 overflow-y-auto customScrollbar2">
              <div className="grid gap-4 py-4">
                <div className="space-y-2">
                  <Label htmlFor="name">Name</Label>
                  <Input
                    id="name"
                    value={selectedData?.name || ""}
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, name: e.target.value });
                      setSelectedData({ ...selectedData, name: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Names" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="query">Query</Label>
                  <Textarea
                    id="query"
                    value={selectedData?.query || ""}
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, query: e.target.value });
                      setSelectedData({ ...selectedData, query: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a query" : ""}
                    className={queryError ? "border-destructive" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="hq">High Quality Terms</Label>
                  <Input
                    id="hq"
                    value={selectedData?.hq || ""}
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, hq: e.target.value });
                      setSelectedData({ ...selectedData, hq: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a High Quality Terms" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="excludeTerms">Exclude Terms</Label>
                  <Input
                    id="excludeTerms"
                    value={selectedData?.exclude_terms || ""}
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, exclude_terms: e.target.value });
                      setSelectedData({ ...selectedData, exclude_terms: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a Exclude Terms" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="exactTerms">Exact Terms</Label>
                  <Input
                    id="exactTerms"
                    value={selectedData?.exact_terms || ""}
                    onChange={(e) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, exact_terms: e.target.value });
                      setSelectedData({ ...selectedData, exact_terms: e.target.value });
                    }}
                    placeholder={selectedRowId ? "Enter a Exact Terms" : ""}
                  />
                </div>
                <div className="space-y-2">
                  <div className="grid grid-cols-[auto_1fr] gap-3">
                    <div className="flex items-center gap-2">
                      <Label htmlFor="filter">Duplicate Filter</Label>
                      <Checkbox
                        id="filter"
                        checked={selectedData?.duplicate_filter || false}
                        onCheckedChange={(e) => {
                          if (!selectedData) return;
                          setEditData({ ...editData, duplicate_filter: e as boolean });
                          setSelectedData({ ...selectedData, duplicate_filter: e as boolean });
                        }}
                      />
                    </div>
                    <div className="flex items-center gap-2">
                      <Label htmlFor="num">Number</Label>
                      <Input
                        id="num"
                        value={selectedData?.num_of_results || ""}
                        onChange={(e) => {
                          if (!selectedData) return;
                          setEditData({ ...editData, num_of_results: parseInt(e.target.value, 10) });
                          setSelectedData({ ...selectedData, num_of_results: parseInt(e.target.value, 10) });
                        }}
                        placeholder={selectedRowId ? "Enter a Number" : ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="space-y-2">
                  <UrlInputForm
                    initialUrls={selectedData?.urls || []}
                    onUrlsChange={(urls) => {
                      if (!selectedData) return;
                      setEditData({ ...editData, urls });
                      setSelectedData({ ...selectedData, urls });
                    }}
                  />
                </div>
                <div className="space-y-2">
                  <DateRestrictForm
                    initialDateRestrict={selectedData?.date_restrict}
                    onDateRestrictChange={(dateRestrict) => {
                      if (!selectedData) return;
                      if (dateRestrict !== selectedData?.date_restrict) {
                        setEditData({ ...editData, date_restrict: dateRestrict });
                        setSelectedData({ ...selectedData, date_restrict: dateRestrict });
                      }
                    }}
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="categories" className="text-xs">
                    Categories
                  </Label>
                  <MultiSelect
                    multiple={true as any}
                    options={categories}
                    value={categories ? categories.filter((cat) => selectedData?.categories?.includes(cat.value)) : []}
                    onChange={(values: SelectOption[]) => {
                      if (!selectedData) return;
                      setEditData({
                        ...editData,
                        categories: values.map((v) => Number(v.value)),
                      });
                      setSelectedData({
                        ...selectedData,
                        categories: values.map((v) => Number(v.value)),
                        categories_name: values.map((v) => v.label),
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center p-4">
              <div className="flex flex-col items-center gap-2">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
                <p className="text-sm text-muted-foreground">Loading monitor data...</p>
              </div>
            </div>
          )}
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsTesting(true);
                setTestMonitor(selectedData);
              }}
            >
              Test Monitor
            </Button>
            <Button variant="outline" onClick={() => handleEditDialog(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>Save Changes</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isDeleting} onOpenChange={handleDeleteDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Monitor</DialogTitle>
            <DialogDescription className="text-destructive text-sm mt-3">Are you sure you want to delete this monitor?</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => handleDeleteDialog(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={() => deleteMonitor(selectedRowId)}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <TestMonitor isTesting={isTesting} setIsTesting={setIsTesting} testMonitor={testMonitor || undefined} />
    </div>
  );
}
