import React from "react";
import type { RouteObject } from "react-router-dom";
import AgentRequiredWrapper from "src/pages/agent/AgentRequiredWrapper";
import { MembershipPermission } from "src/enums/permission";
import LocalPulsePage from "src/pages/agent/local-pulse/localpulsePage";
import LocalMonitorPage from "src/pages/agent/local-pulse/local-monitor/localmonitorPage";
import MonitorConfigPage from "./monitor-config/MonitorConfigPage";
import Dashboard from "./dashboard";

const localPulseRouter: RouteObject[] = [
  {
    path: "local-pulse",
    element: (
      <AgentRequiredWrapper>
        <LocalPulsePage />
      </AgentRequiredWrapper>
    ),
    children: [
      {
        index: true,
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
            <Dashboard />
          </AgentRequiredWrapper>
        ),
      },
      {
        path: "local-monitor",
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
            <LocalMonitorPage />
          </AgentRequiredWrapper>
        ),
        children: [
          {
            path: ":page/",
            element: (
              <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
                <LocalMonitorPage />
              </AgentRequiredWrapper>
            ),
          },
        ],
      },
      {
        path: "monitor-config",
        element: (
          <AgentRequiredWrapper permission={MembershipPermission.ACCESS_AGENT_STR_DATABASE_VERIFICATION}>
            <MonitorConfigPage />
          </AgentRequiredWrapper>
        ),
      },
    ],
  },
];

export default localPulseRouter;
