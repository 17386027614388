import React from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";
import { useConfirmPhoneCodeRequest } from "src/hooks/api/registration";
import { useRedirectOnSuccess } from "src/hooks/redirect";
import { useToastStore } from "src/store/toast";

interface RequestPhoneVerificationProps {
  phone?: string;
  className?: string;
  redirectUrl?: string;
}

const RequestPhoneVerification: React.FC<RequestPhoneVerificationProps> = (
  {
    phone,
    className,
    redirectUrl = "/sign-up/success/",
  },
) => {
  const { t } = useTranslation();
  const { addToastDanger } = useToastStore();

  const {
    mutate,
    isLoading,
    data,
    isSuccess,
    error,
    isError,
  } = useConfirmPhoneCodeRequest();

  useRedirectOnSuccess(
    redirectUrl,
    isSuccess,
    data?.data,
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    mutate({
      phone_number: phone || "",
    });
  };

  React.useEffect(() => {
    if (isError && error.response?.data.detail) {
      addToastDanger({
        title: error.response?.data.detail,
      });
    }
  }, [isError, error]);

  if (!phone) {
    return null;
  }

  return (
    <div className={className}>
      <Text color="danger" fontSize="7" alignment="center">
        {t("components.request_phone_verification.title", "Your phone number is not yet verified.")}
      </Text>
      <div className="text-center pt-2">
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={handleClick}
          loading={isLoading}
        >
          {t("components.request_phone_verification.button_text", "Send verification code")}
        </Button>
      </div>
    </div>
  );
};

export default RequestPhoneVerification;
