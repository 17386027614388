import React from "react";
import config from "src/config";
import FacebookIcon from "src/components/social-login-button/FacebookIcon";
import GoogleIcon from "src/components/social-login-button/GoogleIcon";
import Button from "src/components/ui/form/button";

type SocialLoginType = "facebook" | "google";

interface SocialLoginButtonProps {
  icon: React.ReactNode;
  title: string;
  type: SocialLoginType;
  path?: string;
}

type ISocialLoginButtonFacebook = React.FC<Partial<Pick<SocialLoginButtonProps, "title">>>;
type ISocialLoginButtonGoogle = React.FC<Partial<Pick<SocialLoginButtonProps, "title">>>;

type ISocialLoginButton = React.ExoticComponent<SocialLoginButtonProps> & {
  Facebook: ISocialLoginButtonFacebook;
  Google: ISocialLoginButtonGoogle;
}

const forwardedSocialLoginButton = (
  {
    icon,
    type,
    title,
    path,
  }: SocialLoginButtonProps,
  ref?: React.Ref<HTMLAnchorElement>,
) => {
  const href = React.useMemo(
    () => `${config.API_URL}/auth/socials/login/${path || type}/`,
    [type, path],
  );

  return (
    <Button
      ref={ref}
      title={title}
      href={href}
      variant="outline"
      className="btn-social-login"
      icon={icon}
    >
      {title}
    </Button>
  );
};

const SocialLoginButton = React.forwardRef<HTMLAnchorElement, SocialLoginButtonProps>(forwardedSocialLoginButton) as ISocialLoginButton;

// eslint-disable-next-line react/prop-types
SocialLoginButton.Facebook = ({ title }) => (
  <SocialLoginButton
    type="facebook"
    title={title || "Facebook"}
    icon={<FacebookIcon />}
  />
);

// eslint-disable-next-line react/prop-types
SocialLoginButton.Google = ({ title }) => (
  <SocialLoginButton
    type="google"
    path="google-oauth2"
    title={title || "Google"}
    icon={<GoogleIcon />}
  />
);

export default SocialLoginButton;
