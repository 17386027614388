import { UseQueryResult } from "@tanstack/react-query/src/types";
import { AxiosError, AxiosResponse } from "axios";
import { useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query";
import { getMonitorsCategories, getMonitorsNews } from "src/api/local-pulse";

export const useGetMonitorsCategoriesRequest = () =>
  useQuery(["useGetMonitorsCategoriesRequest", "useGetMonitorsCategoriesRequest"], async () => await getMonitorsCategories());

export const useMonitorsNewsRequest = (params: API.LocalPulse.NewsMonitors.Request) =>
  useQuery(["useMonitorsNewsRequest", "useMonitorsNewsRequest"], async () => await getMonitorsNews(params), {
    enabled: false,
  });
