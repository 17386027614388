import React from "react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./Tooltipz";

interface HoverTooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
}

export const HoverTooltip = ({ children, content }: HoverTooltipProps) => (
  <Tooltip>
    <TooltipTrigger asChild>{children}</TooltipTrigger>
    <TooltipContent>{content}</TooltipContent>
  </Tooltip>
);
