/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { useAuthStore } from "src/store/account";
import { useLoginRequest } from "src/hooks/api/authentication";
import PagePreloader from "src/components/loaders";
import Modal from "react-bootstrap/esm/Modal";
import { useBooleanState } from "src/hooks/boolean";
import { useRegistrationRequest } from "src/hooks/api/registration";
import { Trans, useTranslation } from "react-i18next";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Button from "src/components/ui/form/button";
import { useRedirectOnSuccess, useNextPathNavigate } from "src/hooks/redirect";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import Text from "src/components/ui/text";
import { useProfileHasAnyPermission } from "src/hooks/profile";
import { MembershipPermission } from "src/enums/permission";
import ErrorBoundary from "src/pages/wrappers/ErrorBoundary";
import Divider from "src/components/ui/divider";
import BookCall from "src/components/book-call";
import SocialLogin from "src/components/social-login";
import Form from "react-bootstrap/esm/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import i18next from "i18next";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "src/components/ui/dialog";
import { useForm } from "react-hook-form";
import { ButtonFormGroup, FormGroup } from "src/components/ui/form/group";
import { FormError, FormControlError } from "src/components/ui/form/error";
import { FormControl, FormControlPassword, FormControlPhone } from "src/components/ui/form/control";
import { RequestEmailVerification, RequestPhoneVerification } from "src/components/forms/verifications";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useBreakpointIsLowerOrEqual } from "src/hooks/responsive";

interface IDemoWrapper {
  children: JSX.Element;
  demoComponent?: JSX.Element;
  permission: MembershipPermission | MembershipPermission[];
}

type FormLoginValue = API.Authentication.ObtainToken.Request;

interface TestContext {
  createError: (params: { message: string }) => any;
}

type FormCreateValue = API.Registration.CreateAccount.Request;

const initialLoginFormValue: FormLoginValue = {
  username: "",
  password: "",
  is_remember: false,
};

const initialCreateFormValue: Omit<FormCreateValue, "referral_code"> = {
  email: "",
  password: "",
  phone_number: "",
  password_repeat: "",
  is_privacy_policy_accepted: false,
  is_subscribe_newsletter: true,
  is_sms_opt_in_accepted: false,
};

const CheckboxPrivacyText = () => (
  <Trans
    i18nKey="By creating an account, you agree to our <terms>Terms of use</terms>."
    components={{
      terms: <Text.ExternalLink inherit blank href="https://airvana.co/terms-of-use/" />,
    }}
  />
);

const CheckboxSMSOptInText = () => (
  <Trans
    i18nKey="components.registration.CheckboxSMSOptInText"
    components={{
      terms: <Text.ExternalLink inherit blank href="https://airvana.co/terms-of-use/" />,
    }}
  >
    <span>You agree to receive automated Transactional messages.</span>
    {"Terms and Privacy Policy can be found <terms>here</terms>. "}
    You may receive up to 5 messages per month. Text and data rates may apply. Reply STOP to end or HELP for help.
  </Trans>
);

const RegistrationForm = (props: { setIsLogin: (value: boolean) => void }) => {
  const { setIsLogin } = props;
  const { t } = useTranslation();
  const isMobile = useBreakpointIsLowerOrEqual("sm");

  const requiredMessage = i18next.t("field_required_error", "This field is required.").toString();
  const maxValueTextError = i18next.t("max_value_text_len_error", "The value could not exceed [max] characters.").toString();
  const schema = yup
    .object({
      email: yup.string().email(i18next.t("The given email address is not valid.").toString()).required(requiredMessage),
      password: yup.string().required(requiredMessage),
      phone_number: yup
        .string()
        .max(15, maxValueTextError.replace("[max]", "15"))
        .test("test-phone-number", "Phone number can contain only the following characters: -, (, ), digits and spaces.", (value) => {
          if (!value) {
            return true;
          }
          return value.match(/^[+\d ()-]+$/g) !== null;
        })
        .required(requiredMessage),
      password_repeat: yup
        .string()
        .oneOf([yup.ref("password")], i18next.t("The password confirmation does not match.").toString())
        .required(requiredMessage),
      is_subscribe_newsletter: yup.boolean(),
      is_sms_opt_in_accepted: yup
        .boolean()
        .oneOf([true], i18next.t("You must accept the SMS opt-in to continue.").toString())
        .required(requiredMessage),
      is_privacy_policy_accepted: yup
        .boolean()
        .oneOf([true], i18next.t("You must accept the terms of use.").toString())
        .required(requiredMessage),
    })
    .required();

  const {
    register,
    handleSubmit,
    setError,
    control,
    formState: { errors, isValid },
  } = useForm<FormCreateValue>({
    defaultValues: initialCreateFormValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { data, isError, error, isLoading, mutate, isSuccess } = useRegistrationRequest();

  useRedirectOnSuccess("/sign-up/success/", isSuccess, data?.data);

  const onSubmit = (formData: FormCreateValue) => {
    if (!isValid) {
      return;
    }
    mutate({
      ...formData,
      referral_code: localStorage.getItem("referral") ?? null,
    });
  };

  React.useEffect(() => {
    if (isError && error.response?.data) {
      Object.keys(initialCreateFormValue).forEach((key) => {
        // @ts-ignore
        if (error.response.data[key]) {
          // @ts-ignore
          setError(key, { code: "server", message: error.response.data[key] });
        }
      });
    }
  }, [isError, error]);

  return (
    <DialogContent
      hideClose={!isMobile}
      className="p-4 md:p-6 overflow-y-auto overflow-x-hidden flex max-h-screen max-w-full flex-col md:min-w-[400px] md:max-w-[450px] w-full min-h-screen md:min-h-fit"
    >
      <DialogTitle className="!text-2xl !p-0 text-center">{t("Sign Up")}</DialogTitle>
      <DialogDescription className="sr-only" />
      <div className="flex-1">
        <SocialLogin />
        <Divider text={t("common.or", "or") as string} />
        <Form name="registration" noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormError error={error?.response?.data?.detail} />

          <FormGroup>
            <Form.Label>{t("registration_form.email_label", "Email")}</Form.Label>
            <FormControl
              tabIndex={1}
              type="email"
              required
              autoComplete="email"
              placeholder="example@site.com"
              iconName="email"
              isInvalid={!!errors.email}
              {...register("email")}
            />
            <FormControlError error={errors.email?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Label>{t("registration_form.phone_number_label", "Phone number")}</Form.Label>
            <FormControlPhone tabIndex={2} isInvalid={!!errors.phone_number} name="phone_number" required control={control} />
            <FormControlError error={errors.phone_number?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Label>{t("registration_form.password_label", "Password")}</Form.Label>
            <FormControlPassword
              tabIndex={3}
              autoComplete="new-password"
              required
              isInvalid={!!errors.password}
              {...register("password")}
            />
            <FormControlError error={errors.password?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Label>{t("registration_form.confirm_password_label", "Confirm password")}</Form.Label>
            <FormControlPassword
              tabIndex={4}
              autoComplete="new-password"
              required
              isInvalid={!!errors.password_repeat}
              {...register("password_repeat")}
            />
            <FormControlError error={errors.password_repeat?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Check
              tabIndex={5}
              id="is_subscribe_newsletter"
              label={t("registration_form.subscribe_to_newsletter_label", "I want to receive on-market deals and updates!")}
              isInvalid={!!errors.is_subscribe_newsletter}
              required
              {...register("is_subscribe_newsletter")}
            />
            <FormControlError error={errors.is_subscribe_newsletter?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Check
              tabIndex={6}
              id="is_privacy_policy_accepted"
              label={<CheckboxPrivacyText />}
              isInvalid={!!errors.is_privacy_policy_accepted}
              required
              {...register("is_privacy_policy_accepted")}
            />
            <FormControlError error={errors.is_privacy_policy_accepted?.message} />
          </FormGroup>

          <FormGroup className="!mb-0">
            <Form.Label>
              {t("Already have an account?")}{" "}
              <button type="button" onClick={() => setIsLogin(true)} className="text-decoration-none text-accent-foreground">
                {t("Log in here")}
              </button>
            </Form.Label>
          </FormGroup>

          {/*                     <FormGroup>
            <Form.Check
              tabIndex={6}
              id="is_sms_opt_in_accepted"
              label={<CheckboxSMSOptInText />}
              isInvalid={!!errors.is_sms_opt_in_accepted}
              required
              {...register("is_sms_opt_in_accepted")}
            />
            <FormControlError error={errors.is_sms_opt_in_accepted?.message} />
          </FormGroup> */}

          <ButtonFormGroup>
            <Button loading={isLoading} variant="primary" type="submit" tabIndex={7} style={{ width: "100%" }}>
              {t("registration_form.sign_up_button_text", "Create an account to continue")}
            </Button>
          </ButtonFormGroup>
        </Form>
      </div>
    </DialogContent>
  );
};

const LoginForm = (props: { setIsLogin: (value: boolean) => void }) => {
  const { setIsLogin } = props;
  const { t } = useTranslation();
  const isMobile = useBreakpointIsLowerOrEqual("sm");

  const requiredMessage = i18next.t("field_required_error", "This field is required.").toString();
  const emailInvalidMessage = i18next.t("field_email_error", "Enter a valid email address.").toString();
  const phoneInvalidMessage = i18next
    .t("field_phone_error", "Enter a valid phone number. The phone number should start with +1 and contain only digits without spaces.")
    .toString();
  const emailSchema = yup.string().email(emailInvalidMessage).required(requiredMessage);

  const testEmailOrPhone = (value: any, { createError }: TestContext) => {
    if (!value) {
      return createError({
        message: requiredMessage,
      });
    }

    if (value.startsWith("+") || !Number.isNaN(parseInt(value, 10))) {
      if (value.match(/^\+[0-9]{11}$/g) === null) {
        return createError({
          message: phoneInvalidMessage,
        });
      }
      return true;
    }

    return emailSchema.validateSync(value);
  };

  const schema = yup
    .object({
      username: yup.string().test("email_or_phone", testEmailOrPhone),
      password: yup.string().required(requiredMessage),
      is_remember: yup.boolean(),
    })
    .required();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<FormLoginValue>({
    defaultValues: initialLoginFormValue,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const { error, mutate, status, data, isError, isLoading } = useLoginRequest();
  const [previousFormData, setPreviousFormData] = React.useState<Partial<FormLoginValue> | null>(null);
  const onSubmit = (formData: FormLoginValue) => {
    if (!isValid) {
      return;
    }
    setPreviousFormData({
      ...formData,
      password: "",
    });
    mutate(formData);
  };

  const { setAccessToken, setRefreshToken, setIsAuthenticated } = useAuthStore();
  const location = useLocation();
  const nextPathNavigate = useNextPathNavigate(location.pathname);

  React.useEffect(() => {
    if (status === "success" && data?.data) {
      setAccessToken(data.data.access);
      setRefreshToken(data.data.refresh);
      setIsAuthenticated(true);
      nextPathNavigate();
    }
  }, [status]);
  React.useEffect(() => {
    if (isError && error?.response?.data) {
      Object.keys(initialLoginFormValue).forEach((key) => {
        // @ts-ignore
        if (error.response.data[key]) {
          // @ts-ignore
          setError(key, { code: "server", message: error.response.data[key][0] });
        }
      });
    }
  }, [isError, error]);
  const canRequestEmailVerification = React.useMemo(() => {
    if (!error?.response?.data?.code) {
      return false;
    }

    const hasEmail = previousFormData?.username?.includes("@");
    if (typeof error.response.data.code === "string") {
      return hasEmail && error.response.data.code === "email_not_confirmed";
    }

    return hasEmail && error.response.data.code.includes("email_not_confirmed");
  }, [error, previousFormData]);

  const canRequestPhoneVerification = React.useMemo(() => {
    if (!error?.response?.data?.code) {
      return false;
    }

    const hasPhone = previousFormData?.username?.startsWith("+1");
    if (typeof error.response.data.code === "string") {
      return hasPhone && error.response.data.code === "phone_not_confirmed";
    }

    return hasPhone && error.response.data.code.includes("phone_not_confirmed");
  }, [error, previousFormData]);

  return (
    <DialogContent
      hideClose={!isMobile}
      className="p-4 md:p-6 overflow-y-auto overflow-x-hidden flex flex-col md:min-w-[400px] md:max-w-[450px] w-full min-h-screen md:min-h-fit"
    >
      <DialogTitle className="!text-2xl !p-0 text-center">{t("Sign In")}</DialogTitle>
      <Divider text={t("common.or", "or") as string} />
      <div className="flex-1">
        <SocialLogin />
        <Divider text={t("common.or", "or") as string} />
        <Form name="login" noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormError className="mb-4" error={error?.response?.data?.detail} />

          <FormGroup>
            <Form.Label>{t("Email or phone number")}</Form.Label>
            <FormControl
              tabIndex={1}
              type="text"
              autoComplete="login"
              placeholder={t("Email or phone number") as string}
              required
              iconName="fingerprint"
              isInvalid={!!errors.username}
              {...register("username")}
            />
            <FormControlError error={errors.username?.message} />
          </FormGroup>

          <FormGroup>
            <Form.Label>{t("Password")}</Form.Label>
            <FormControlPassword
              tabIndex={2}
              required
              type="password"
              autoComplete="current-password"
              isInvalid={!!errors.password}
              {...register("password")}
            />
            <FormControlError error={errors.password?.message} />
          </FormGroup>

          <Row>
            <Col xs={12}>
              <FormGroup>
                <Form.Check tabIndex={3} id="is_remember" {...register("is_remember")} label={t("Keep me logged in")} />
              </FormGroup>
            </Col>
            <Col xs={12}>
              <Text.Link
                style={{ paddingTop: 2 }}
                alignment="end"
                color="muted"
                fontSize="7"
                fontWeight="semibold"
                to="/login/forgot-password/"
              >
                {t("Forgot password?")}
              </Text.Link>
            </Col>
          </Row>

          <FormGroup className="!mb-0">
            <Form.Label>
              {t("Don't have an account?")}{" "}
              <button type="button" onClick={() => setIsLogin(false)} className="text-decoration-none text-accent-foreground">
                {t("Sign up here")}
              </button>
            </Form.Label>
          </FormGroup>

          <ButtonFormGroup>
            <Button
              type="submit"
              loading={isLoading}
              variant="primary"
              tabIndex={4}
              onClick={handleSubmit(onSubmit)}
              style={{ width: "100%" }}
            >
              {t("Sign In")}
            </Button>
          </ButtonFormGroup>
          {canRequestEmailVerification && <RequestEmailVerification className="pt-4" email={previousFormData?.username!} />}
          {canRequestPhoneVerification && <RequestPhoneVerification className="pt-4" phone={previousFormData?.username!} />}
        </Form>
      </div>
    </DialogContent>
  );
};

const DemoWrapper: React.FC<IDemoWrapper> = ({ children, demoComponent, permission }) => {
  const { isAuthenticated } = useAuthStore();
  const { t } = useTranslation();
  const [isLogin, setIsLogin] = React.useState(false);
  const { state: isModalShow, setTrue: showModal, setFalse: closeModal } = useBooleanState(isLogin);

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    event.stopPropagation();
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.bubbles = false;
    showModal();
    return false;
  };

  const canAccessFeature = useProfileHasAnyPermission(permission);
  const demoForm = isLogin ? <LoginForm setIsLogin={setIsLogin} /> : <RegistrationForm setIsLogin={setIsLogin} />;
  if (!canAccessFeature) {
    return (
      <React.Suspense fallback={<PagePreloader />}>
        <div style={{ position: "relative" }}>
          {demoComponent || (
            <div className="px-5 text-center">
              <h3 className="mb-5">
                {isAuthenticated
                  ? t("Oops! This feature is not available to your membership level.")
                  : t("Oops! This feature is only available for Airvana members.")}
              </h3>
              {isAuthenticated ? (
                <Link to="/membership/select/" className="text-decoration-none">
                  <Button>{t("Upgrade your membership")}</Button>
                </Link>
              ) : (
                <Link to="/sign-up/" className="text-decoration-none">
                  <Button>{t("Sign up for free")}</Button>
                </Link>
              )}
            </div>
          )}
          <a
            onClick={handleClick}
            tabIndex={-1}
            title=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 100,
            }}
          />
        </div>
        <Dialog open={isModalShow} onOpenChange={closeModal}>
          {isAuthenticated ? (
            <DialogContent className="p-4 md:p-6 overflow-y-auto overflow-x-hidden flex max-h-screen max-w-full flex-col md:min-w-[400px] md:max-w-[450px] w-full min-h-screen md:min-h-fit">
              <DialogTitle>{t("Oops! This feature is only available for Airvana members.")}</DialogTitle>
              <DialogDescription className="sr-only" />
              <div className="flex-1">
                <Col xs={{ span: 22, offset: 1 }}>
                  <Text fontSize="7" alignment="start" style={{ marginBottom: 16 }}>
                    {t("Upgrade your membership today to get nationwide access to all our features:")}
                  </Text>
                  <ul className="av-list av-list--small">
                    <li className="av-list__item">
                      {t("Evaluate thousands of properties at once with automated underwriting and evaluation")}
                    </li>
                    <li className="av-list__item">{t("Customize and edit financial analysis for any property")}</li>
                    <li className="av-list__item">{t("Explore deals across hundreds of markets, all in one place")}</li>
                    <li className="av-list__item">
                      {t("Sort and filter properties to view opportunities relevant your investment criteria")}
                    </li>
                    <li className="av-list__item">{t("Receive automatic alerts for deals that match your investment criteria")}</li>
                    <li className="av-list__item">{t("Explore hundreds of new properties every day")}</li>
                  </ul>
                </Col>
              </div>
              <DialogFooter className="flex !flex-col">
                <Link className="w-100 d-block" to="/membership/select/">
                  <Button className="w-100">{t("Upgrade your membership")}</Button>
                </Link>
                <Divider className="my-4 w-100" text={t("or") as string} />
                <BookCall className="w-100" buttonText={t("Speak with sales")} />
              </DialogFooter>
            </DialogContent>
          ) : (
            demoForm
          )}
        </Dialog>
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback={<PagePreloader />}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </React.Suspense>
  );
};

export default DemoWrapper;
