/* eslint-disable max-len */
import React from "react";

interface FacebookIconProps {
  style?: React.CSSProperties;
  size?: number | string;
}

const FacebookIcon: React.FC<FacebookIconProps> = (
  {
    style,
    size = "1rem",
  },
) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12.2719 13.7352L12.9496 9.43337H8.77516V6.6372C8.77516 5.46092 9.35795 4.31153 11.2215 4.31153H13.1461V0.64828C12.0253 0.469716 10.8928 0.373113 9.75777 0.359253C6.32203 0.359253 4.07897 2.42277 4.07897 6.15324V9.43337H0.270508V13.7352H4.07897V24.1402H8.77516V13.7352H12.2719Z"
      fill="#337FFF"
    />
  </svg>
);

export default FacebookIcon;
