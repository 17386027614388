import React, { useCallback, useState } from "react";
import { Button } from "src/components/ui/button";
import { Label } from "src/components/ui/label";
import { Textarea } from "src/components/ui/textarea";
import { useAgentGetLocalMonitorsRequest } from "src/hooks/api/agent/local-pulse";
import { DialogFooter } from "src/components/ui/dialog";
import { Switch } from "src/components/ui/switch";
import { QueryInputForm } from "../component/query-input-form";
import { ParamsType } from "./EditableTable";

export interface MonitorConfig extends AgentAPI.LocalPulse.MonitorConfig {
  showDetails: boolean;
}

interface customEditableTableParams {
  is_active: boolean;
}

interface DialogContentProps {
  initialAreaIds?: number[];
  onQueriesChange: (queries: AgentAPI.LocalPulse.MonitorConfig[]) => void;
  error: {
    id: number;
    query: boolean;
    urls: boolean;
  };
  setError: (error: { id: number; query: boolean; urls: boolean }) => void;
  displayAreaName?: string | number | readonly string[] | undefined;
  handleSave: () => void;
  onParamsChange: (type: ParamsType, value: any, areaId: number[]) => void;
  currentData: AgentAPI.LocalPulse.LocalMonitorData[];
  setIsEditing: (isEditing: boolean) => void;
}

const DialogContent = ({
  initialAreaIds = [],
  onQueriesChange,
  error,
  setError,
  displayAreaName,
  handleSave,
  onParamsChange,
  currentData,
  setIsEditing,
}: DialogContentProps) => {
  const { data: queriesData, isLoading, refetch } = useAgentGetLocalMonitorsRequest({ area_ids: initialAreaIds });
  const [queries, setQueries] = useState<MonitorConfig[]>([]);
  const [originalMonitors, setOriginalMonitors] = useState<MonitorConfig[]>([]);
  const [params, setParams] = useState<customEditableTableParams>({
    is_active: currentData?.length > 0 ? currentData.every((monitor) => monitor.is_active) : false,
  });
  const [originalParams, setOriginalParams] = useState<customEditableTableParams>({
    is_active: currentData?.length > 0 ? currentData.every((monitor) => monitor.is_active) : false,
  });
  const previousAreaIds = React.useRef<number[]>([]);
  const [dataUnmatch, setDataUnmatch] = useState<boolean>(false);

  React.useEffect(() => {
    if (!isLoading && queriesData && initialAreaIds.length > 0) {
      // Get all monitors from all areas
      const allMonitors = Object.values(queriesData.data).flat();

      // Create a map to count how many areas each monitor ID appears in
      const monitorCounts = new Map<number, number>();
      allMonitors.forEach((monitor) => {
        const count = monitorCounts.get(monitor.id) || 0;
        monitorCounts.set(monitor.id, count + 1);
      });

      // Check if each monitor appears in all areas
      const allAreasHaveSameMonitors = Array.from(monitorCounts.values()).every((count) => count === initialAreaIds.length);
      setDataUnmatch(!allAreasHaveSameMonitors);
      if (allAreasHaveSameMonitors) {
        // If all areas have the same monitors, set the queries using monitors from any area
        const firstAreaMonitors = queriesData.data[initialAreaIds[0]] || [];
        setQueries(firstAreaMonitors.map((monitor) => ({ ...monitor, showDetails: false })));
        setOriginalMonitors(firstAreaMonitors.map((monitor) => ({ ...monitor, showDetails: false })));
      }
    }
  }, [isLoading, queriesData]);

  React.useEffect(() => {
    if (initialAreaIds.length > 0 && JSON.stringify(previousAreaIds.current) !== JSON.stringify(initialAreaIds)) {
      refetch();
      previousAreaIds.current = initialAreaIds;
    }
  }, [initialAreaIds, refetch]);

  const checkMonitorChanges = useCallback(() => {
    if (JSON.stringify(queries) === JSON.stringify(originalMonitors)) {
      return false;
    }
    return true;
  }, [queries, originalMonitors]);

  const checkParamsChanges = useCallback(() => {
    if (JSON.stringify(params) === JSON.stringify(originalParams)) {
      return false;
    }
    return true;
  }, [params, originalParams]);

  return (
    <>
      <div className="flex-1 overflow-y-auto customScrollbar2">
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="areaName">Area Names</Label>
            <Textarea id="areaName" disabled value={displayAreaName} className={`h-[60px] ${dataUnmatch ? "border-red-500" : ""}`} />
            {dataUnmatch && (
              <p className="text-red-500 text-sm">The data does not match the selected areas so you will not able to edit the monitors.</p>
            )}
          </div>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Switch id="monitor-switch" checked={params.is_active} onCheckedChange={(checked) => setParams({ ...params, is_active: checked })} />
              <Label htmlFor="monitor-switch">Enable monitoring</Label>
            </div>
          </div>
          {!dataUnmatch && (
            <div className="space-y-2">
              <QueryInputForm
                queries={queries}
                setQueries={setQueries}
                onQueriesChange={onQueriesChange}
                error={error}
                setError={setError}
              />
            </div>
          )}
        </div>
      </div>
      <DialogFooter>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            onClick={() => {
              setIsEditing(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if(checkMonitorChanges()) {
                handleSave();
              }
              if(checkParamsChanges()) {
                onParamsChange("Status", params.is_active, initialAreaIds);
              }
              previousAreaIds.current = [];
            }}
            disabled={!checkMonitorChanges() && !checkParamsChanges()}
          >
            Save Changes
          </Button>
        </div>
      </DialogFooter>
    </>
  );
};

export default DialogContent;
