import React from "react";
import { useNavigate } from "react-router-dom";
import type { AxiosError } from "axios";
import { useNextPathStore, useRedirectContextStore } from "src/store/redirect-context";
import { useToastStore } from "src/store/toast";

export const useRedirectOnSuccess = (path: string, isSuccess: boolean, context?: Object, timeoutSeconds?: number) => {
  const navigate = useNavigate();
  const {
    setContext,
  } = useRedirectContextStore();

  React.useEffect(() => {
    if (isSuccess) {
      navigate(path);
      if (context !== undefined) {
        setContext(context);
      }
    }
  }, [isSuccess, path]);
};

type IUseRedirectOnNotFound = (error: AxiosError<any> | null) => void;

export const useRedirectOnNotFound: IUseRedirectOnNotFound = (error) => {
  const navigate = useNavigate();
  const { addToastDanger } = useToastStore();
  React.useEffect(() => {
    if (error && error?.response?.status === 404) {
      navigate("/page-not-found/");
    }
    if (error?.response?.data.detail) {
      addToastDanger({
        title: error.response.data.detail,
      });
    }
  }, [error]);
};

type IUseNextPathNavigate = (defaultPath?: string) => () => void;

export const useNextPathNavigate: IUseNextPathNavigate = (defaultPath = "/properties/") => {
  const { nextPath, clearNextPath } = useNextPathStore();
  const navigate = useNavigate();

  return () => {
    if (nextPath) {
      navigate(nextPath);
      clearNextPath();
    } else {
      navigate(defaultPath);
    }
  };
};
