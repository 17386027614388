import { makeAnonymousRequest } from "src/api/request";

export const createAccount = async (data: API.Registration.CreateAccount.Request) => (
  await makeAnonymousRequest.post<API.Registration.CreateAccount.Response>("/auth/registration/", data)
);

export const requestPasswordReset = async (data: API.Registration.RequestPasswordReset.Request) => (
  await makeAnonymousRequest.post<API.Registration.RequestPasswordReset.Response>("/auth/reset-password/request/", data)
);

export const resetPassword = async (data: API.Registration.ResetPassword.Request) => (
  await makeAnonymousRequest.post<API.Registration.ResetPassword.Response>("/auth/reset-password/", data)
);

export const confirmEmail = async (data: API.Registration.ConfirmEmail.Request) => (
  await makeAnonymousRequest.post<API.Registration.ResetPassword.Response>("/auth/registration/confirm-email/", data)
);

export const confirmEmailRequest = async (data: API.Registration.ConfirmEmailRequest.Request) => (
  await makeAnonymousRequest.post<API.Registration.ConfirmEmailRequest.Response>("/auth/registration/request-confirm-email/", data)
);

export const subscribeToNewsletter = async (data: API.Registration.SubscribeToNewsletter.Request) => (
  await makeAnonymousRequest.post<API.Registration.SubscribeToNewsletter.Response>("/accounts/email-subscription/", data)
);

export const verifyPhoneNumber = async (data: API.Registration.VerifyPhoneNumber.Request) => (
  await makeAnonymousRequest.post<API.Registration.VerifyPhoneNumber.Response>("/auth/registration/confirm-phone/", data)
);

export const confirmPhoneRequest = async (data: API.Registration.ConfirmPhoneRequest.Request) => (
  await makeAnonymousRequest.post<API.Registration.ConfirmPhoneRequest.Response>("/auth/registration/request-confirm-phone/", data)
);
