import React from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";
import { useConfirmEmailRequestRequest } from "src/hooks/api/registration";
import { useRedirectOnSuccess } from "src/hooks/redirect";
import { useToastStore } from "src/store/toast";

interface RequestEmailVerificationProps {
  email?: string;
  className?: string;
  redirectUrl?: string;
}

const RequestEmailVerification: React.FC<RequestEmailVerificationProps> = (
  {
    email,
    className,
    redirectUrl = "/sign-up/success/",
  },
) => {
  const { t } = useTranslation();
  const { addToastDanger } = useToastStore();

  const {
    mutate,
    isLoading,
    data,
    isSuccess,
    error,
    isError,
  } = useConfirmEmailRequestRequest();

  useRedirectOnSuccess(
    redirectUrl,
    isSuccess,
    data?.data,
  );

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    mutate({
      email: email || "",
    });
  };

  React.useEffect(() => {
    if (isError && error.response?.data.detail) {
      addToastDanger({
        title: error.response?.data.detail,
      });
    }
  }, [isError, error]);

  if (!email) {
    return null;
  }

  return (
    <div className={className}>
      <Text color="danger" fontSize="7" alignment="center">
        {t("components.request_email_verification.title", "Your email is not yet verified.")}
      </Text>
      <div className="text-center pt-2">
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={handleClick}
          loading={isLoading}
        >
          {t("components.request_email_verification.button_text", "Send verification email")}
        </Button>
      </div>
    </div>
  );
};

export default RequestEmailVerification;
