import React from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import SocialLoginButton from "src/components/social-login-button";
import { useSearchParams } from "react-router-dom";

const SocialLogin: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const showFacebook = React.useMemo(() => searchParams.get("facebook") === "true", [searchParams]);
  return (
    <Row>
      {showFacebook && (
        <Col xs={24} sm={12} className="mb-2 mb-sm-0">
          <SocialLoginButton.Facebook title={t("Sign in with Facebook") as string} />
        </Col>
      )}
      <Col xs={24} sm={showFacebook ? 12 : 24}>
        <SocialLoginButton.Google title={t("Sign in with Google") as string} />
      </Col>
    </Row>
  );
};

export default SocialLogin;
