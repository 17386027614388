import React from "react";
import { useMenuActive } from "src/hooks/menu";
import Button from "src/components/ui/form/button";
import Text from "src/components/ui/text";

export interface ChipMenuItemProps {
  to: string;
  title: string;
  level: 1 | 2 | 3 | 4 | 5;
  style?: React.CSSProperties;
}

const ChipMenuItem: React.FC<ChipMenuItemProps> = (
  {
    to,
    level,
    title,
    style,
  },
) => {
  const isActive = useMenuActive(to, level);
  return (
    <Text.Link to={to}>
      <Button
        style={style}
        variant={isActive ? "primary" : "chip"}
        size="sm"
      >
        {title}
      </Button>
    </Text.Link>
  );
};

export default ChipMenuItem;
