import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button } from "src/components/ui/button";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Input } from "src/components/ui/input";
import { Loader2, RefreshCw } from "lucide-react";
import { Checkbox } from "src/components/ui/checkbox";
import { getMonitorsList } from "src/api/agent/local-pulse";
import { MonitorConfig } from "../local-monitor/CustomDialogContent";

interface ExistingMonitorFormProps {
  setIsOpen: (open: boolean) => void;
  onSelectMonitors: (monitors: MonitorConfig[]) => void;
  currentMonitors: MonitorConfig[];
}

export default function ExistingMonitorForm({ setIsOpen, onSelectMonitors, currentMonitors }: ExistingMonitorFormProps) {
  const [search, setSearch] = useState("");
  const [selectedMonitors, setSelectedMonitors] = useState<MonitorConfig[]>(currentMonitors);
  const parentRef = useRef<HTMLDivElement>(null);
  // Fetch monitors with pagination
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading, refetch } = useInfiniteQuery({
    queryKey: ["monitors", search],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getMonitorsList({
        page: pageParam,
        limit: 50,
        search,
      });
      return response;
    },
    getNextPageParam: (lastPage, pages) => (lastPage.data.hasMore ? pages.length + 1 : undefined),
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });
  // Flatten all pages into a single array
  const allMonitors: MonitorConfig[] = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.data.results.map((monitor) => ({
          ...monitor,
          showDetails: false,
        })),
      ) ?? [],
    [data],
  );
  // Set up virtualization
  const virtualizer = useVirtualizer({
    count: allMonitors.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 10,
  });

  // Load more when scrolling near bottom
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      const target = e.target as HTMLDivElement;
      if (target.scrollHeight - target.scrollTop <= target.clientHeight * 1.5 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  return (
    <div className="flex flex-col h-full">
      {/* Search Input */}
      <div className="mb-4">
        <Input placeholder="Search monitors..." value={search} onChange={(e) => setSearch(e.target.value)} className="w-full" />
      </div>
      {/* Virtualized List */}
      <div ref={parentRef} onScroll={handleScroll} className="flex-1 overflow-auto min-h-[400px] max-h-[60vh]">
        <div
          style={{
            height: `${virtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const monitor = allMonitors[virtualRow.index];
            return (
              <div
                key={virtualRow.index}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                }}
                className="flex items-center space-x-2 p-2 hover:bg-slate-200 rounded cursor-pointer"
              >
                <Checkbox
                  checked={selectedMonitors.some((m) => m.id === monitor.id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setSelectedMonitors([...selectedMonitors, monitor]);
                    } else {
                      setSelectedMonitors(selectedMonitors.filter((m) => m.id !== monitor.id));
                    }
                  }}
                />
                <span className="text-sm">{monitor.name || monitor.query}</span>
              </div>
            );
          })}
        </div>
      </div>
      {/* Loading indicator */}
      {(isLoading || isFetchingNextPage) && (
        <div className="flex justify-center py-2">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      )}
      {/* Actions */}
      <div className="flex justify-end space-x-2 mt-4">
        <Button
          variant="outline"
          onClick={() => {
            setSearch("");
            refetch();
          }}
        >
          <RefreshCw className="h-4 w-4" />
        </Button>
        <Button variant="outline" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSelectMonitors(selectedMonitors);
            setSelectedMonitors([]);
            setIsOpen(false);
          }}
          disabled={selectedMonitors.length === 0}
        >
          Add Selected ({selectedMonitors.length})
        </Button>
      </div>
    </div>
  );
}
